class NotificationForm
  constructor: (form) ->
    @form = $(form)
    @previewSubmit = @form.find("#preview-submit")
    @submitBtn = @form.find(":submit:not([data-preview-path])")
    @validator = @form.parsley()

    # set up recipient list
    @recipients = @form.find(".recipient-list")
    @recipientsToggles = @form.find(".recipient-list-toggles")
    @recipientsTable = @recipients.find("table")
    @recipientsRows = @recipientsTable.find("tbody tr")

    @init()

  init: ->
    # set up form
    @syncSubmitBtn()

    submitUrl = @form.attr("action")
    previewUrl = @previewSubmit.data("preview-path")

    @previewSubmit.on "click", (e) =>
      # tell form to submit to the preview URL
      @form.attr("action", previewUrl)
      # disable file inputs so they don't get uploaded for the preview
      @form.find(".file :input").attr("disabled", "disabled")

      Form.submit(@form)

    @recipientsRows.on "change", "input[type='checkbox']", (e) => @syncSubmitBtn()

    @recipientsToggles.find("button").on "click", (e) => @toggleRole(e.currentTarget)

    @form.on "ajax:complete", (e) =>
      # reset url in case preview url set
      @form.attr("action", submitUrl)

      # re-enable inputs in case disabled from preview
      @form.find(".file :input").removeAttr("disabled")

  toggleRole: (trigger) ->
    btn = $(trigger)
    roleRows = @recipientsRows.filter (_, r) -> r.dataset.role == btn.data('role')

    if btn.data("active")
      btn.data("active", false).attr('data-active', false)
      roleRows.find("input[type='checkbox']").prop('checked', false)
    else
      btn.data("active", true).attr('data-active', true)
      roleRows.find("input[type='checkbox']").prop('checked', true)

    @syncSubmitBtn()

  syncSubmitBtn: ->
    receiveCnt = @recipientsRows.find("input[type='checkbox']:checked").length

    if receiveCnt <= 0
      @submitBtn.val("Please Add Recipients")
      @submitBtn.attr("disabled", "disabled")
    else
      txt = "Send Notification to #{receiveCnt} Participant"
      txt += "s" if receiveCnt > 1

      @submitBtn.filter("[disabled]").removeAttr("disabled")
      @submitBtn.css("text-transform", "none").val(txt)


$(document).on "exercise_notifications/new.load exercise_notifications/create.load", ->
  form = $(".exercise-notification-form")
  new NotificationForm(form) if form.length > 0

$(document).on "course_offering_notifications/new.load course_offering_notifications/create.load", ->
  form = $(".course-offering-notification-form")
  new NotificationForm(form) if form.length > 0
