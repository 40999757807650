class window.DemographicSearch
  constructor: (select, options={}) ->
    @select = $(select)
    @setup(options)

  @init: (container) ->
    container.find("select.demographic-search").each (idx, s) => new DemographicSearch(s)

  setup: (options) ->
    selectOptions = $.extend({}, @defaultOptions(), options)
    @select.selectize selectOptions

  defaultOptions: ->
    inactive         = @select.data("search-inactive") || false
    legacy           = @select.data("search-legacy") || false
    userOnly         = @select.data("search-user-only") || false
    includeEmail     = @select.data("show-email") || false
    availableOptions = @select.data("available-options")
    availableOptions = [availableOptions] unless $.isArray(availableOptions)
    selectedOptions  = @select.data("selected-options")
    selectedOptions  = [selectedOptions] unless $.isArray(selectedOptions)

    {
      valueField: "id"
      labelField: "full_name"
      searchField: ["fema_sid", "full_name"]
      options: availableOptions
      items: selectedOptions
      render:
        option: (demo, escape) ->
          femaSid = if demo.fema_sid? then "<strong>#{escape(demo.fema_sid)}</strong>" else "<em>(No FEMA SID)</em>"
          name = escape(demo.full_name)
          mainText = name

          if includeEmail
            email = if demo.email? then escape(demo.email) else "no email"
            mainText += " <em>(#{email})</em>"

          "<div>" +
            "<span class='name'>" +
              "#{femaSid}<span class='muted'> &mdash; #{mainText}</span>" +
            "</span>" +
          "</div>"

        item: (demo, escape) ->
          femaSid = ""
          femaSid += "<span class='muted'> &mdash; (#{escape(demo.fema_sid)})</span>" if demo.fema_sid?
          name = escape(demo.full_name)

          "<div>" +
            "<span class='name'>#{name}#{femaSid}</span>" +
          "</div>"

      load: (query, callback) =>
        data = {q: query, excluded: @getExcluded(), legacy: legacy, inactive: inactive, user_only: userOnly}

        if @select.data("permission-through") != undefined
          data['permission_through'] = {}
          data['permission_through']['id'] = @select.data("permission-through").id
          data['permission_through']['class'] = @select.data("permission-through").class
          data['permission_through']['permission'] = @select.data("permission-through").permission

        $.ajax
          url: @select.data("load-path")
          type: "GET"
          dataType: "json"
          data: data
          error: => callback()
          success: (res) => callback(res)
    }

  getExcluded: =>
    excluded = @select.data("excluded")
    excluded = new Array if excluded == undefined
    selected = @select.val()

    # exclude already selected values from new options
    if selected?
      selected = [selected] unless $.isArray(selected)

      for value in selected
        excluded.push(value) if $.inArray(value, excluded) < 0

    excluded

class DemographicMergeForm
  constructor: ->
    @form = $(".demographic-merge-search-form")
    @searchFields = @form.find("select.demographic-search")

    @searchFields.each (idx, field) =>
      field.selectize.on "change", =>
        @searchFields.not(field)[0].selectize.clearOptions() # reset option list (so we can reactivate previously disabled option, if needed)

      field.selectize.on "load", (data) =>
        otherVal = @searchFields.not(field).val()

        # remove option that's already selected in the other dropdown search
        field.selectize.removeOption(otherVal) unless otherVal == ""

class DemographicFields
  constructor: ->
    # This conditionally assigns required field validation on parish field of demographic fields.
    # If the region field is set to "Out-of-state", then parish shouldn't be required.
    @regionField = $("select[data-out-of-state-region]")
    @parishField = @regionField.closest("form").find(".demo-parish-field")

    return false unless @regionField.length > 0 && @parishField.length > 0

    outOfStateVal = @regionField.data("out-of-state-region").toString()

    # disable parish selection if out-of-state selected in region field
    @regionField.on "change", (e) =>
      if @regionField.val().toString() == outOfStateVal
        @parishField
          .attr "disabled", "disabled"
          .removeAttr "data-parsley-required"
          .find "option"
          .attr "disabled", "disabled"
          .filter "[value='']"
          .removeAttr "disabled"
          .prop "selected", true
          .text "Out-of-State"
          .closest ".form-group"
          .find "label .warning-text"
          .hide()
      else
        @parishField
          .removeAttr "disabled"
          .attr "data-parsley-required", ""
          .find "option"
          .removeAttr "disabled"
          .filter "[value='']"
          .text "-- Select one --"
          .closest ".form-group"
          .find "label .warning-text"
          .show()

    .trigger("change")

    # auto-assign region from parish field if region is blank
    @parishField.on "change", (e) =>
      if @parishField.val() != ""
        regionId = @parishField.find("option:selected").data("region-id")
        @regionField.val(regionId) if regionId != "" && regionId != undefined

class CourseCreditsTranscriptsFields
  constructor: ->
    @form = $(".course-credit-transcripts-form")
    @form.find(".add-association").on "association.added", (event, newFields) =>
      CourseSearch.init(@form)
      DateInput.initInputs(newFields) # init date inputs

    @form.on "click", ".remove-association", (event) ->
      if $(".course-credit-fields").length >= 2
        fields = $(event.currentTarget).closest(".course-credit-fields")
        fields.slideUp -> fields.remove()

$(document).on "demographics/merges/new.load demographics/merges/create.load", ->
  new DemographicMergeForm

$(document).on "admin/index.load demographics/new.load demographics/create.load " +
               "demographics/edit.load demographics/update.load " +
               "users/new.load users/create.load " +
               "users/edit.load users/update.load " +
               "demographics/conflicts/edit.load demographics/conflicts/update.load" , ->
  new DemographicFields

$(document).on "js.load", ->
  searchFields = $(".demographic-search")
  new DemographicSearch(searchField) for searchField in searchFields

$(document).on "users/show.load demographics/show.load", ->
  history = $(".course-history")
  printBtn = $(".profile-print-button")

  printBtn.on "click", ->
    history.addClass("noprint")
    print()

    history.removeClass("noprint")

$(document).on "course_credits_transcripts/new.load course_credits_transcripts/create.load", ->
  new CourseCreditsTranscriptsFields