class MandatoryCoursesReports
  constructor: ->
    $(document).on "reports.loaded", => @initReport()

  initReport: ->
    for editor in $(".in-place-editor")
      new HireDateForm(editor)

    $('.table-scroll-wrapper1').scroll ->
       $('.table-scroll-wrapper2').scrollLeft $('.table-scroll-wrapper1').scrollLeft()
    $('.table-scroll-wrapper2').scroll ->
      $('.table-scroll-wrapper1').scrollLeft $('.table-scroll-wrapper2').scrollLeft()

class HireDateForm
  constructor: (editor) ->
    @editor = $(editor)
    @display = @editor.find(".in-place-editor-display")
    @init()


  init: ->
    Form.initForms(@editor)
    DateInput.initInputs(@editor) # initalize plugin for date fields
    initInPlaceEditor @editor, (resp) => @updateDisplay(resp) # initalize in place form


  updateDisplay: (formResponse) ->
    if formResponse.hire_date?
      @display.html(Formatter.formatDate(formResponse.hire_date))
    else
      @display.html("<em class='muted'>None Set</em>")

$(document).on "reports/mandatory_courses_reports/show.load", ->
  new MandatoryCoursesReports()