/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
import 'src/css/application';

// IE11 polyfills
import 'core-js/stable';

require('@rails/ujs').start();
require('turbolinks').start();
require('selectize');
require('parsleyjs');
require('responsive-slides');
require('fullcalendar');

import 'inputmask';

import 'src/js/formatter';
import 'src/js/date_input';
import 'src/js/editor';
import 'src/js/forms';
import 'src/js/filters';
import 'src/js/modals';
import 'src/js/flash';
import 'src/js/toggler';
import 'src/js/tooltips';
import 'src/js/ellipsis';
import 'src/js/file_upload';
import 'src/js/switch_toggler';
import 'src/js/submenu_toggler';
import 'src/js/in_place_editor';
import 'src/js/conflict_search';
import 'src/js/env';

import 'src/js/calendar'
import 'src/js/cancellation_forms';
import 'src/js/courses';
import 'src/js/course_offerings';
import 'src/js/course_offering_applications';
import 'src/js/course_offering_participants';
import 'src/js/course_quizzes';
import 'src/js/demographics';
import 'src/js/demographic_fiscal_years';
import 'src/js/exercises';
import 'src/js/exercise_document_imports';
import 'src/js/exercise_participants';
import 'src/js/exports';
import 'src/js/home';
import 'src/js/imports';
import 'src/js/instructor_applications';
import 'src/js/notifications';
import 'src/js/pages';
import 'src/js/reports';
import 'src/js/reports/mandatory_courses_reports';
import 'src/js/users';

// Put code in here that needs to initialize on multiple pages
var loadShared = function(){
  // Nav toggling for medium and smaller screens
  $(".nav-toggle a").on("click", function(){
    $("#nav").stop().slideToggle(function(){
      // reset fixed height so nested menus don't overflow when toggled
      this.style.height = "auto";
    });
  });

  $("select[data-selectize]").selectize({copyClassesToDropdown : false});

  $(".actions").on("toggleable.toggled", "[data-toggle]", function(event, toggleable, visible){
    var triggerIcon = $(event.target).find("i");

    if (visible) {
      triggerIcon.addClass("fa-spin") }
    else {
      triggerIcon.removeClass("fa-spin") };
  });

  // clone association fields
  $("form").on("click", ".add-association", function(event){
    event.preventDefault()

    var button = $(event.currentTarget);
    var time = new Date().getTime();
    var regexp = new RegExp(button.data("hash").id, 'g');
    var content = $(button.data("hash").fields.replace(regexp, time));

    if (button.data("append-to")) {
      $(button.data("append-to")).append(content);
    } else {
      button.before(content).prev();
    }

    button.trigger("association.added", content);
  });

  Tooltip.init($("[data-tooltip]"));

  return true;
};

var loadJavascript = function(){
  loadShared(); // this is js that runs on every page

  $(document).trigger("js.load"); // global trigger
  $(document).trigger(Env.controller() + "/" + Env.action() + ".load"); // page-specific trigger

  return true;
};

var initJavascript = function(){
  var body = document.getElementsByTagName("BODY")[0];
  var init = body.getAttribute("data-js-init") !== "true";

  if (init) {
    body.setAttribute("data-js-init", "true")
    loadJavascript();
    return true;
  }

  return false;
};

$(document).ready(initJavascript);
$(document).on("turbolinks:load", initJavascript);