class ApplicationReviewModal
  constructor: (modal, trigger) ->
    @modal = modal
    @trigger = trigger
    @container = $(@modal.modal)
    @forms = @container.find("form") # multiple forms

    @roleField = @container.find("select[name$='[role]']")
    @instrPermSubField = @container.find("#instructor-perms-subfields")
    @instrPermToggler = new Toggler(@instrPermSubField)

    @init()

  init: ->
    denialSwitch = @container.find("[data-switch]")
    denialSwitch.on "switch.switched", => @modal.checkOverflow()

    @roleField.on "change", =>
      if @roleField.data("instructor-roles").includes(@roleField.val()) then @instrPermToggler.show() else @instrPermToggler.hide()

    @forms.on "ajax:success", (event, resp) =>
      body = $('body')
      msg =
        switch resp.status
          when "approved" then "<span class='success-text'>Applicant Approved!</span>"
          when "denied" then "<span class='warning-text'>Applicant Denied.</span>"

      postContent = $(msg).hide()

      @modal.destroy()
      @trigger.replaceWith(postContent.slideDown())


class ApplicationReviewButton
  constructor: (button) ->
    @button = $(button)
    @button.on "modal:loaded", (e, modal) =>
      new ApplicationReviewModal(modal, @button)

$(document).on "js.load", ->
  reviewBtns = $(".application-review-button")
  new ApplicationReviewButton(reviewBtn) for reviewBtn in reviewBtns