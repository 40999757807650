class CourseQuizForm
  QUESTION_POSITION_FIELD_SELECTOR: ".course-quiz-question-position"
  QUESTION_FIELD_SELECTOR: ".course-quiz-question-fields"

  constructor: ->
    @form = $("form.course-quiz-form")
    @initForm()

  initForm: ->
    # initial form set up
    @syncQuestionList()
    @toggleRequiredSubfields($(fields)) for fields in @form.find(@QUESTION_FIELD_SELECTOR)

    @form.find(".add-quiz-question").on "association.added", (event, association) => @initNewQuestionFields(association)
    @form.on "click", "[data-direction]", (event) => @changePos($(event.currentTarget))
    @form.on "click", ".remove-question", (event) => @removeQuestion($(event.currentTarget))
    @form.on "click", ".remove-question-option", (event) => @removeQuestionOption($(event.currentTarget))
    @form.on "association.added", ".add-quiz-question-option", (event) => @syncQuestionOptionsList($(event.currentTarget).closest(@QUESTION_FIELD_SELECTOR))
    @form.on "switch.switched", "[name$='[question_type]']", (event) => @toggleRequiredSubfields($(event.currentTarget).closest(@QUESTION_FIELD_SELECTOR))
    @form.on "change", ":input[name$='[question_type]']", (event) => @toggleTrueFalseAutoGradeFields($(event.currentTarget).closest(@QUESTION_FIELD_SELECTOR))


  initNewQuestionFields: (newFields) ->
    newFields = $(newFields)
    newFields.on "change", ".course-quiz-correct-answer-cbx", (event) ->
      if newFields.find("[name$='[question_type]']:checked").val() == "multiple_choice"
        cb = event.currentTarget

        if cb.checked
          newFields.find(".course-quiz-correct-answer-cbx").not(cb).prop("checked", false)

    @syncQuestionList()

  syncQuestionList: ->
    fields = @form.find(@QUESTION_FIELD_SELECTOR)
    fields.each (idx, item) =>
      item = $(item)
      item.find(".subheader").text (_, curText) -> curText.replace(/\d+/, "#{idx + 1}")
      item.find(@QUESTION_POSITION_FIELD_SELECTOR).val(idx)

      @syncQuestionOptionsList(item)

      dirBtns = item.find("[data-direction]").removeAttr("disabled")
      dirBtns.filter("[data-direction='1']").attr("disabled", "disabled") if idx == 0
      dirBtns.filter("[data-direction='-1']").attr("disabled", "disabled") if idx == (fields.length - 1)

  syncQuestionOptionsList: (questionFields) ->
    list = questionFields.find("ol")
    list.find("li").each (idx, item) ->
      item = $(item)
      item.find("[name$='[position]']").val(idx)

  toggleRequiredSubfields: (questionFields) ->
    list = questionFields.find("ol")
    fields = list.find("[name$='[answer_text]']")

    if list.is(":visible") then fields.attr("required", "required") else fields.removeAttr("required")

  toggleTrueFalseAutoGradeFields: (questionFields) ->
    if questionFields.find(":input[name$='[question_type]']:checked").val() == "true_false"
      questionFields.find(".option-true-false-fields").show()
    else
      questionFields.find(".option-true-false-fields").hide()

  removeQuestion: (trigger) ->
    fields = trigger.closest(@QUESTION_FIELD_SELECTOR)
    fields.slideUp =>
      if fields.data("persisted")
        idField     = fields.find("[name$='[id]']").detach()
        deleteField = idField.clone()
        deleteField.attr "name", (idx, val) -> val.replace(/\[id\]/, "[archived]")
        deleteField.val(true)

        # moves them to the end of form so it doesn't interfere with switching question positions
        @form.append(idField).append(deleteField)

      fields.remove()

      @syncQuestionList()

  removeQuestionOption: (trigger) ->
    fields = trigger.closest("li")
    fields.slideUp =>
      list = fields.closest(@QUESTION_FIELD_SELECTOR)

      if fields.data("persisted")
        idField     = fields.next("[name$='[id]']")
        deleteField = idField.clone()
        deleteField.attr "name", (idx, val) -> val.replace(/\[id\]/, "[_destroy]")

        fields.replaceWith(deleteField)
      else
        fields.remove()

      @syncQuestionOptionsList(list)

  changePos: (trigger) ->
    dir = trigger.data("direction")
    sourceFields = trigger.closest(@QUESTION_FIELD_SELECTOR)
    targetFields = if dir < 0 then sourceFields.next(@QUESTION_FIELD_SELECTOR) else sourceFields.prev(@QUESTION_FIELD_SELECTOR)

    @switchElements sourceFields, targetFields, dir > 0, => @syncQuestionList()

  switchElements: (sourceElement, targetElement, moveUp, callback) ->
    return if targetElement.length < 1

    # resolve defaults
    if $.isFunction(moveUp)
      callback = moveUp
      moveUp = false

    targetPos = sourceElement.height()
    targetPos = "-" + targetPos unless moveUp

    # target element movement
    targetElement
      .css({'z-index' : 999, 'position' : 'relative'})
      .animate({top: targetPos}, 250)

    sourcePos = targetElement.height()
    sourcePos = "-" + sourcePos if moveUp

    # animate source element movement
    sourceElement
      .css({'z-index' : 1000, 'position' : 'relative'})
      .animate {top: sourcePos}, 300, ->
        targetElement.css({'z-index' : '', 'top' : '', 'position' : ''})
        sourceElement.css({'z-index' : '', 'top' : '', 'position' : ''})

        if moveUp
          sourceElement.insertBefore(targetElement)
        else
          sourceElement.insertAfter(targetElement)

        callback.call() if $.isFunction(callback)


$(document).on "course_quizzes/new.load course_quizzes/create.load course_quizzes/edit.load course_quizzes/update.load", ->
  new CourseQuizForm()