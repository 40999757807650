class Calendar
  constructor: ->
    $calendar = $("#calendar")
    $calendar.fullCalendar
      defaultDate: $calendar.data('default-date'),
      eventColor: "#1ba3ef",
      validRange:
        start: $calendar.data('min-date')
      events:
        url:   'calendar/load_events',
        cache: true
      eventClick: (calEvent, jsEvent, view) ->
        window.location = '/courses/' + calEvent.course_id + '/offerings/' + calEvent.offering_id
      eventAfterRender: (calEvent, jsEvent, view) ->
        jsEvent.addClass("fc-event-cancelled") if calEvent.cancelled

$(document).on "calendar/index.load", -> new Calendar()