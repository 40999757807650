class InstructorApplicationQuestionForm
  constructor: ->
    @form = $(".instructor-application-question-form")
    @questionOptions = @form.find("#option-fields")
    @switches = @form.find("[data-switch]")

    @setTriggers()


  setTriggers: ->
    @switches.on "switch.switched", => @setOptionRequiredValidation()
    @form.find(".add-association").on "association.added", =>
      @reindexOptions()
      @setOptionRequiredValidation()

    @questionOptions.on "click", ".remove-question-option", (event) =>
      fields = $(event.currentTarget).closest("li")
      fields.slideUp =>
        if fields.data("persisted")
          idField     = fields.next("[name$='[id]']")
          deleteField = idField.clone()
          deleteField.attr "name", (idx, val) -> val.replace(/\[id\]/, "[_destroy]")

          fields.replaceWith(deleteField)
        else
          fields.remove()

        @reindexOptions()

    @reindexOptions()

  reindexOptions: ->
    $(optionFields).find("[name$='[position]']").val(idx) for optionFields, idx in @questionOptions.find("li")

  setOptionRequiredValidation: ->
    fields = @form.find("[name$='[answer_text]']")

    if @questionOptions.is(":visible") then fields.attr("required", "required") else fields.removeAttr("required")


$(document).on "instructor_application_questions/new.load instructor_application_questions/edit.load", -> new InstructorApplicationQuestionForm()
