class TrackFormChanges
  constructor: (form) ->
    @form     = $(form)
    @fieldIdx = 0
    @origVals = {}

    @trackChanges()

  trackChanges: ->
    # store original values to compare with on submission
    for trackedField in @trackedFields()
      @setChangeTrackedFieldIndex(trackedField)

    # intercept form submission to check if any of the tracked fields have changed
    @form.on "submit", (e) =>
      # remove action if it's canceled, but replace it if they say yes
      if @anyChanges() && !@acceptChanges()
        if (@form.attr("data-action") == undefined)
          @form.attr("data-action", @form.attr("action"))
        @form.attr("action", "")
      else
        @form.attr("action", @form.attr("data-action"))

  trackedFields: -> @form.find("[data-track-change]")

  # prompt user to tell them we'll be notifying participants of changes
  acceptChanges: ->
    not_wording = if $("#course_offering_send_notification:checked").length == 1 then "" else "NOT"
    confirm "Looks like there were changes made to the location or time. " +
            "Registered participants will #{not_wording} be notified of these changes. " +
            "The notification can be changed below the date and time. " +
            "Are these changes final?\n\nNOTE: refresh page to reset fields."

  # compare current values with original values and return 'true' if any don't match
  anyChanges: ->
    for trackedField in @trackedFields()
      field = $(trackedField)
      index = field.data("track-change")

      # index hasn't been set, so it's a new field and therefore has changed
      return true if typeof(index) == "string" && /\d+/.test(index)

      newVal  = field.val()
      changed = @origVals[index] != newVal

      return true if changed

    false

  setChangeTrackedFieldIndex: (trackedField) ->
    field = $(trackedField)
    field.data("track-change", @fieldIdx)

    # set value at index saved in field's data attr for retrieval later
    @origVals[@fieldIdx] = field.val()

    # increment index for next field
    @fieldIdx++

class PointOfContactSearch
  constructor: (searchField) ->
    @searchField = $(searchField)
    @init()

  init: ->
    @searchField.selectize {
      render:
        option: (item, escape) ->
          nameLine = "<strong>#{escape(item.text)}</strong>"
          phoneLine = if item.phone_number? " &mdash; <span class='muted'>#{item.phone_number}</span>" then else ""

          "<div>#{nameLine}#{phoneLine}</div>"

        item: (item, escape) ->
          nameLine = "<strong>#{escape(item.text)}</strong>"
          phoneLine = if item.phone_number? then " &mdash; <span class='muted'>#{item.phone_number}</span>" else ""

          "<div>#{nameLine}#{phoneLine}</div>"
    }


class CourseOfferingSummary
  constructor: ->
    @completionCertificateModal()

  completionCertificateModal: ->
    $(".course-completion-button").on "modal:loaded", (e, modal) =>
      m          = $(modal.modal)
      testBtns   = m.find("input[name$='[include_test]']")
      testFields = m.find("#participants-scores")
      partList   = m.find("#participants-list")

      testBtns.on "change", ->
        val = testBtns.filter(":checked").val()
        if val == "true"
          partList.hide()
          testFields.show()
        else
          testFields.hide()
          partList.show()

        # check if modal content overflows window
        modal.checkOverflow()

      m.find(".select_all").on "change", ->
        m.find(".status-#{$(this).data('select-all')}").each (idx, btn) =>
          $(btn).prop("checked", true)

      certBtns   = m.find("input[name$='[include_cert]']")
      certFields = m.find("#cert-subfields")

      if certFields.length > 0
        postCertBtns = certFields.find("input[name$='[include_post_on_cert]']")
        certBtns.on "switch.switched", (e) ->
          value = certBtns.filter(":checked").val()
          validated = postCertBtns.filter("[data-parsley-required]")
          validated.attr("data-parsley-required", value == "true")

          # check if modal content overflows window
          modal.checkOverflow()

        certBtns.first().trigger("switch.switched")

class CourseOfferingForm
  constructor: ->
    @form              = $("form.course-offering-form")
    @audienceField     = @form.find(":input[name$='[audience]']")
    @approvalTypeField = @form.find(":input[name$='[manual_approval]']")
    @newFacilities     = []

    @initForm()

    new TrackFormChanges(@form) if @form.data("track-changes")

  initForm: ->
    @form.on "submit", (e) =>
      if @canceledFundingSourceRemoval()
        e.preventDefault()
        e.stopPropagation()

    @form.find("#course_offering_advert_only").on "switch.switched", ->
      fields = $("#advert-fields")
      if fields.is(":visible")
        fields.find(":input[name$='[advert_url]']").attr("required", "required")
      else
        fields.find(":input[name$='[advert_url]']").removeAttr("required")

    @form.find(".new-option-modal[data-modal]").each (idx, el) =>
      trigger   = $(el)
      fieldName = trigger.data("modal-field")

      unless fieldName == "facility" # facilities get initialized separately
        trigger.on "modal:loaded", (e, modal) => @initModal(trigger, modal, fieldName)

    @audienceField.on "change", (e) =>
      @setApprovalType(@audienceField.val() == "invite_only")
    .trigger "change"

    # initialize selectize
    @initFacilityFields(@form)
    @initPointOfContactFields(@form)

    @form.find(".add-association").on "association.added", (event, newFields) =>
      @initFacilityFields(newFields)
      @addAndSelectNewOption("facility", newOpt) for newOpt in @newFacilities # add newly created facilities to option list
      @copyPrevFieldValues(newFields)
      DateInput.initInputs(newFields) # init date inputs

    @form.on "click", ".remove-association", (event) ->
      button = $(event.currentTarget)
      fields = button.closest(".date-and-time-fields")

      fields.slideUp ->
        if fields.data("persisted")
          idField     = fields.next("[name$='[id]']")
          deleteField = idField.clone()
          deleteField.attr "name", (idx, val) -> val.replace(/\[id\]/, "[_destroy]")

          fields.replaceWith(deleteField)
        else
          fields.remove()

    @form.find(".registration-deadline-switch").on "switch.switched", (e) =>
      @updateToggledDatetimeFields(e.currentTarget)
    .trigger("switch.switched")

    ### events for quiz and evaluation scheduling fields ###
    scheduleSwitches = @form.find("[name$='[schedule_pretest]'], [name$='[schedule_posttest]'], [name$='[schedule_evaluation]']")
    scheduleSwitches.on "switch.switched", (e) =>
      @updateToggledDatetimeFields(e.currentTarget)

    # init fields
    scheduleSwitches.each (idx, trigger) ->
      $(trigger).trigger("switch.switched")

    @form.find(".datetime-select-fields").on "change", (e) =>
      trigger = $(e.currentTarget)
      fieldset = trigger.closest("fieldset")

      @syncDatetimeSelectValue(fieldset)

  initFacilityFields: (scope) ->
    container = $(scope)
    facilityFields = container.find("[name$='[facility_id]']")

    # initialize modal for adding new facilities
    container.find(".new-option-modal[data-modal][data-modal-field='facility']").on "modal:loaded", (e, modal) => @initModal(e.currentTarget, modal, 'facility')

    # initialize selectize
    new FacilitySearch(facilityFields)

  initPointOfContactFields: (scope) ->
    field = $(scope).find("[name$='[point_of_contact_id]']")

    new PointOfContactSearch(field)

  updateToggledDatetimeFields: (toggleTrigger) ->
    trigger = $(toggleTrigger)
    target = $(trigger.data("switch"))

    if target.is(":visible")
      @syncDatetimeSelectValue(target)
    else
      target.find(".datetime-select-value").val("")

  # used for quiz scheduling and custom registration deadline fields
  syncDatetimeSelectValue: (fieldset) ->
    fields = fieldset.find(".datetime-select-fields")

    day = fields.filter("[name*='3i']").val()
    month = fields.filter("[name*='2i']").val() - 1
    year = fields.filter("[name*='1i']").val()

    hour = fields.filter("[name*='4i']").val()
    minute = fields.filter("[name*='5i']").val()

    date = new Date(year, month, day, hour, minute)

    fieldset.find(".datetime-select-value").val(date.toLocaleString())

  copyPrevFieldValues: (newFields) ->
    fields     = $(newFields)
    prevFields = fields.prevAll(".date-and-time-fields").first()

    # copy previous field values to new fields
    if prevFields.length > 0
      prevFacilityField  = prevFields.find("[name$='[facility_id]']")
      prevStartDateField = prevFields.find("[name$='[start_date]']")
      prevEndDateField   = prevFields.find("[name$='[end_date]']")
      prevTimeField      = prevFields.find("[name$='[time]']")
      prevStartTimeField = prevFields.find("[name$='[start_time]']")
      prevEndTimeField   = prevFields.find("[name$='[end_time]']")

      dateVal = prevEndDateField.val()
      dateVal = prevStartDateField.val() if dateVal == ""

      fields.find("[name$='[facility_id]']")[0].selectize.setValue(prevFacilityField.val())
      fields.find("[name$='[start_date]'], [name$='[end_date]']").val()
      fields.find("[name$='[time]']").val(prevTimeField.val())
      fields.find("[name$='[start_time]']").val(prevStartTimeField.val())
      fields.find("[name$='[end_time]']").val(prevEndTimeField.val())

  addAndSelectNewOption: (option, fieldName) ->
    switch fieldName
      when "facility"
        facilityFields = @form.find(":input[name$='[facility_id]']")

        for field in facilityFields
          selectize = field.selectize
          selectize.addOption({text: option.name, value: option.id, address: option.address})

      when "vendor"
        select = @form.find("select[name$='[vendor_id]']")
        select.append("<option value='#{option.id}'>#{option.name}</option>")
        select.val(option.id)

      when "sponsor"
        select = @form.find("select[name$='[sponsor_ids][]']")
        selectize = select[0].selectize
        selectize.addOption({text: option.name, value: option.id})

        # get current value so we don't clear selected values when setting new value
        newVal = selectize.getValue()
        newVal.push option.id

        selectize.setValue(newVal)
      when "point_of_contact"
        selectize = @form.find("select[name$='[point_of_contact_id]']")[0].selectize
        selectize.addOption({text: option.name, value: option.id, phone_number: option.phone_number})
        selectize.setValue(option.id)


  initModal: (trigger, modal, fieldName) ->
    modalTrigger = $(trigger)
    modalElement = $(modal.modal)
    modalForm    = modalElement.find("form")

    modalForm.on "ajax:success", (event, resp) =>
      # adds option to field and selects as current value
      @addAndSelectNewOption(resp, fieldName)

      if fieldName == "facility"
        # add to list of new facilities so it gets added to appended date range fields
        @newFacilities.push(resp)
        # select new facility option for facility field
        input = modalTrigger.closest(".field-group").find(":input[name$='[facility_id]']")
        selectize = input[0].selectize
        selectize.setValue(resp.id)

      modal.close() # hide modal
      Form.reset(modalForm) # clear fields and errors

  setApprovalType: (disable) ->
    if disable
      @approvalTypeField.addClass("disabled").find("option").attr("disabled", "disabled").filter("[value='true']").removeAttr("disabled").prop("selected", true)
    else
      @approvalTypeField.removeClass("disabled").find("option").removeAttr("disabled")

  # checks if we need to warn user for removing a funding source that's linked to offering costs
  canceledFundingSourceRemoval: ->
    field = @form.find("select[name$='[funding_source_ids][]']")
    sources = $.extend({}, field.data("original-funding-sources")) # must use $.extend to clone the object so the original doesn't get modified

    return false if typeof(sources) != 'object' || $.isEmptyObject(sources)

    for id, name of sources
      delete sources[id] if id in field.val()

    return false if $.isEmptyObject(sources)

    sourcesText = "\n"
    sourcesText += "\n#{name}" for id, name of sources

    !confirm "You removed the following funding sources that were still linked to offering costs: #{sourcesText}\n\n" +
             "Are you sure you want to continue? You will need to manually update the costs if necessary."

class CourseOfferingEvaluationForm
  constructor: ->
    @triggers = $(document).find("td.response-cell")
    @init()

  init: ->
    # select checkbox if enclosing cell clicked
    @triggers.on "click", (e) ->
      triggerField = $(e.currentTarget).find("[type='radio']")

      if triggerField.length > 0 && e.target.type != 'radio'
        triggerField.prop("checked", true)
        triggerField.trigger("change")

    # toggle response fields when answer changed
    @triggers.on "change", "[type='radio']", (e) ->
      triggerField = $(e.currentTarget)
      rowFields = triggerField.closest("tr")
      respFields = rowFields.next("tr")

      if rowFields.find("[type='radio']:checked").val() == "Needs Improvement"
        respFields.show().find(":input").removeAttr("disabled").attr("required" , "required")
      else
        respFields.hide().find(":input").attr("disabled", "disabled").removeAttr("required")

    # set initial form state
    for t in @triggers
      $(t.querySelector("[type='radio']")).trigger('change')


$(document).on "course_offerings/new.load course_offerings/create.load course_offerings/edit.load course_offerings/update.load", ->
  new CourseOfferingForm()

$(document).on "course_offerings/show.load", ->
  new CourseOfferingSummary()

$(document).on "course_offering_evaluations/new.load course_offering_evaluations/create.load", ->
  new CourseOfferingEvaluationForm()
