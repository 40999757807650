class CourseOfferingParticipantForm
  constructor: (form) ->
    @form = $(form)
    @initForm()

  initForm: ->
    roleField = @form.find("select[name$='[role]']")
    statusField = @form.find("select[name$='[status]']")
    demoSearchField = @form.find(".demographic-search")
    instrPermSubField = @form.find("#instructor-perms-subfields")
    cancelSubField = @form.find("#cancel-reason-subfields")
    noticeSubField = @form.find("#acceptance-notice-subfields")
    instrPermToggler = new Toggler(instrPermSubField)
    cancelToggler  = new Toggler(cancelSubField)
    noticeToggle   = new Toggler(noticeSubField)

    roleField.on "change", ->
      if roleField.data("instructor-roles").includes(roleField.val()) then instrPermToggler.show() else instrPermToggler.hide()

    statusField.on "change", ->
      if statusField.val() == "cancelled" then cancelToggler.show() else cancelToggler.hide()
      if statusField.val() == "registered" then noticeToggle.show() else noticeToggle.hide()

    @form.find(":submit").on "click", (e) => e.preventDefault() if @cancelFromMissingSID(demoSearchField)

  cancelFromMissingSID: (demoSearchField) ->
    return false unless @form.data("require-fema-sid") && demoSearchField.length > 0

    selected = demoSearchField[0].selectize.getOption(demoSearchField.val())

    selected.length > 0 &&
    !/\d{10}/.test(selected.text()) && # look for FEMA SID in selected demographic
    !confirm("This course requires a FEMA SID on registration and the selected demographic doesn't have a FEMA SID. Would you still like to proceed?")


$(document).on "course_offering_participants/new.load " +
               "course_offering_participants/create.load " +
               "course_offering_participants/edit.load " +
               "course_offering_participants/update.load", ->

  forms = $(".registration-form")
  new CourseOfferingParticipantForm(form) for form in forms
